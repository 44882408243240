import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import TransitionAlert from './transition-alert';
import { EP, callApi } from '../api';
import useFormInput from './use-form-input';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(4),
  },
  checkbox: {
    fontSize: '0.8em',
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
}));

const toId = id => id.toLowerCase().replace(/[^a-z0-9._]/g, '');

function StoreForm({
  action,
  store,
}) {
  const { t } = useTranslation('storeForm');
  const classes = useStyles();
  const id = useFormInput(store ? store.id : '', toId);
  const name = useFormInput(store ? store.name : '');
  const phone = useFormInput(store && store.details ? store.details.phone : '');
  const addressLine1 = useFormInput(store && store.details ? store.details.addressLine1 : '');
  const addressLine2 = useFormInput(store && store.details ? store.details.addressLine2 : '');
  const coupon = useFormInput(store && store.details ? store.details.coupon : '');
  const scheduleLine1 = useFormInput(store && store.details ? store.details.scheduleLine1 : '');
  const scheduleLine2 = useFormInput(store && store.details ? store.details.scheduleLine2 : '');
  const scheduleLine3 = useFormInput(store && store.details ? store.details.scheduleLine3 : '');
  const emailEnabled = useFormInput(store && store.notifications ? store.notifications.emailEnabled : false);
  const smsEnabled = useFormInput(store && store.notifications ? store.notifications.smsEnabled : false);
  const notificationEmail = useFormInput(store && store.notifications ? store.notifications.emailAddress : '');
  const notificationPhone = useFormInput(store && store.notifications ? store.notifications.notificationPhone : '');
  const initialFormState = { isSubmitting: false, isSuccess: false, error: null }
  const [form, setForm] = useState(initialFormState); 
  const isInvalidForm = (
    !id.isValid 
    || !name.isValid 
    || (emailEnabled.value && !notificationEmail.isValid)
    || (smsEnabled.value && !notificationPhone)
  ); 
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setForm({ ...initialFormState, isSubmitting: true });
    const params = Object.assign(
      {
        body: Object.assign(
          {
            name: name.value,
            details: {
              phone: phone.value,
              addressLine1: addressLine1.value,
              addressLine2: addressLine2.value,
              coupon: coupon.value,
              scheduleLine1: scheduleLine1.value,
              scheduleLine2: scheduleLine2.value,
              scheduleLine3: scheduleLine3.value,
            },
            notifications: {
              emailEnabled: emailEnabled.value,
              smsEnabled: smsEnabled.value,
              emailAddress: notificationEmail.value,
              phoneNumber: smsEnabled.value ? notificationPhone : '',
            },
          },
          action === 'add' ? { id: id.value } : {}
        )
      }, 
      action === 'edit' ? { storeId: store.id } : {}
    );
    try {
      const endpoint = action === 'add' ? EP.stores.post : EP.stores.patch;
      await callApi(endpoint, params);
      setForm({ ...initialFormState, isSuccess: true});
    } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message)
        || t('errorSaving'); 
      setForm({ ...initialFormState, error: message });
    }
  }
  const isAddSuccess = form.isSuccess && action === 'add';
  return (
    <Container component="main" maxWidth="xs">
      { isAddSuccess && (
         <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('messageSuccess')}</TransitionAlert>
      )}
      { form.isSuccess && (
        <Button 
          className={classes.buttons}
          component={Link}
          to="/stores" 
          fullWidth  
          variant="contained"
          color="primary"
        >
            {t('seeAllStores')} 
        </Button>
      )}
      { form.isSuccess && action ==='add' && (
        <Button 
          className={classes.buttons}
          fullWidth  
          variant="outlined"
          onClick={() => setForm(initialFormState)}
        >
            {t('addAnotherStore')}
        </Button>
      )}
      { !isAddSuccess && (
          <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            { action === 'add' ? t('titleAdd') : t('titleEdit') }
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              autoFocus
              readOnly={action === 'edit'}
              fullWidth
              id="id"
              label="ID"
              margin="normal"
              name="id"
              required
              type="text"
              variant="outlined"
              value={id.value}
              onChange={id.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="name"
              label={t('labelName')}
              margin="normal"
              name="name"
              required
              type="text"
              variant="outlined"
              value={name.value}
              onChange={name.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="phone"
              label={t('labelPhone')}
              margin="normal"
              name="phone"
              type="text"
              variant="outlined"
              value={phone.value}
              onChange={phone.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="coupon"
              label={t('labelCoupon')}
              margin="normal"
              name="coupon"
              type="text"
              variant="outlined"
              value={coupon.value}
              onChange={coupon.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="addressLine1"
              label={t('labelAddress1')}
              margin="normal"
              name="addressLine1"
              type="text"
              variant="outlined"
              value={addressLine1.value}
              onChange={addressLine1.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="addressLine2"
              label={t('labelAddress2')}
              margin="normal"
              name="addressLine2"
              type="text"
              variant="outlined"
              value={addressLine2.value}
              onChange={addressLine2.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="scheduleLine1"
              label={t('labelSchedule1')}
              margin="normal"
              name="scheduleLine1"
              type="text"
              variant="outlined"
              value={scheduleLine1.value}
              onChange={scheduleLine1.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="scheduleLine2"
              label={t('labelSchedule2')}
              margin="normal"
              name="scheduleLine2"
              type="text"
              variant="outlined"
              value={scheduleLine2.value}
              onChange={scheduleLine2.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="scheduleLine3"
              label={t('labelSchedule3')}
              margin="normal"
              name="scheduleLine3"
              type="text"
              variant="outlined"
              value={scheduleLine3.value}
              onChange={scheduleLine3.onChange}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={emailEnabled.value}
                    onChange={emailEnabled.onChange}
                    name="emailEnabled"
                    color="primary"
                  />
                }
                label={<Typography className={classes.checkbox}>{t('emailEnabled')}</Typography>}
              />
            </FormGroup>
            { emailEnabled.value && (
              <TextField
                inputProps={{
                  readOnly: Boolean(form.isSubmitting),
                }}
                fullWidth
                id="notificationEmail"
                label={t('notificationEmail')}
                margin="normal"
                name="notificationEmail"
                required
                type="email"
                variant="outlined"
                value={notificationEmail.value}
                onChange={notificationEmail.onChange}
              />
            )}
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={smsEnabled.value}
                    onChange={smsEnabled.onChange}
                    name="smsEnabled"
                    color="primary"
                  />
                }
                label={<Typography className={classes.checkbox}>{t('smsEnabled')}</Typography>}
              />
            </FormGroup>
            { smsEnabled.value && (
              <TextField 
                inputProps={{
                  readOnly: Boolean(form.isSubmitting),
                }}
                fullWidth
                id="notificationPhone"
                label={t('notificationPhone')}
                margin="normal"
                name="notificationPhone"
                variant="outlined"
                type="text"
                value={notificationPhone.value} 
                onChange={notificationPhone.onChange}
              />
            )}
            <div>
              <TransitionAlert className={classes.alert} severity="error" isOpen={form.error}>{form.error}</TransitionAlert>
              <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('messageSuccess')}</TransitionAlert>
            </div>
            <Grid container spacing={1} className={classes.buttons}>
              <Grid item xs={6}>
                <Button 
                  component={Link} 
                  type="button"
                  fullWidth
                  variant="outlined"
                  to="/stores"  
                >
                    {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={form.isSubmitting || isInvalidForm}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleOnSubmit}
                >
                  { form.isSubmitting ? t('saving') : t('save') }
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Container>
  );
}

export default StoreForm;
