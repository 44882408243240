import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from "use-query-params";

import { EP, callApi } from '../../api';
import TransitionAlert from '../../components/transition-alert';
import Loading from '../../components/loading';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import StoreForm from '../../components/store-form';

const EditStorePage = ({path}) => {
  const auth = useAuth();
  const [id] = useQueryParam('id', StringParam);
  const { store, isLoading, isError } = useFetchStore(auth, id);
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Edit store" />
      { isLoading && <Loading /> }
      { isError && (
        <TransitionAlert severity="error" isOpen>
          There was an error fetching the store.
        </TransitionAlert>
      )}
      { store && (
        <StoreForm action="edit" store={store} />
      )}
    </Layout>
  )
}

const useFetchStore = (auth, storeId) => {
  const [store, setStore] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const store = await callApi(EP.stores.get, { storeId });
        setIsLoading(false);
        setStore(store);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, [auth]);
  
  return { store, isLoading, isError };
}

export default EditStorePage;
